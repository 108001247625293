import { createStore } from 'vuex';
import { assign } from 'lodash';
import storeConfig from './storeConfig';
import SdkStoreConfig from '@nsftx/games-sdk-js/src/store/storeConfig';

export default createStore(
  assign(storeConfig, {
    modules: SdkStoreConfig.modules,
  }),
);
