export default {
  gameConfig: {}, // config/definition per game (local json)
  // Temp config for VirtualSoccer
  config: {},
  /* config: {
    environment: 'staging',
    platformName: 'seven',
    productName: 'CrashCash', // VirtualSoccer
    productId: '81a0ee67-6a8d-4936-8ff3-21adb2c8cd5c', // channel or cpvUuid
    tenantId: 'b99752b3-443c-4c80-b559-945a95c4b805', // company or companyUuid,
    productInstanceUuid: 'b547a709-cf11-4ea8-a3dd-6f7d87706c16',
    translationDomains: [],
    locale: 'en',
    applicationName: 'shop', // 'Retail'
  }, */ // config on load from Gateway
  //  TODO: support games/products mapper and details
  activeProductName: '',
  productList: ['CrashCash', 'VirtualSoccer', 'GreyhoundRaces', 'LuckySix', 'GamesTest'],
  ticketPreviewData: null,
  ticketPreviewActive: false,
  betStatuses: {
    positive: ['won', 'paidout', 'expired'],
    neutral: ['open', 'pending', 'accepted'],
    negative: ['lost', 'canceled', 'rejected'],
  },
  ticketStatuses: {
    positive: ['won', 'paidout'],
    neutral: ['open', 'pending', 'accepted'],
    negative: ['lost', 'canceled', 'expired', 'rejected'],
  },
  // Available values on 7platform: Add, Cancel, Payout
  ticketActionMap: {
    PayIn: 'Add',
  },
  ticketIcons: {
    positive: 'n-i-check-a',
    neutral: 'n-i-clock',
    negative: 'n-i-close',
  },
  ticketAction: '',
  eventTime: 0,
  eventId: '-',
  eventName: '',
  eventInProgress: true,
  // Connection
  connectionActive: true,
  connectionLostTimestamp: null,
  connectionLostInterval: null,
  connectionLostDuration: 0,
  connectionOfflineLimit: 10,
  translations: {},
  ticketActionSuccessful: true,
  autoPayout: false,
  payoutConfirmation: {},
  configServiceInit: false,
  busServiceInit: false,
  loadApp: false,
  deviceId: '',
  printedTicketsByTicketCheck: [],
  ticketsPrintedByTicketUpdate: [],
  ticketChecksInProgress: [],
  ticketsScheduledToBeChecked: [],
  bettingDisabled: false,
  activeNotifications: [],
  upcomingEvents: [],
  futureRounds: 1,
  connectionReconnectActive: false,
  shouldGetUpcomingEvents: true,
  reconnectAtemptCounter: 0,
  maxReconnectAtemptNumber: 8,
};
