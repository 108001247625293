export default {
  CrashCash: {
    gameColor: '#178685',
    futureBet: false,
    systemBet: false,
    bettingType: 'other',
    messaging: 'WebSocket', // WebSocket support in games-bus-js  with options
    autoTicketCheckTimeout: 5000,
    betslipTypes: [
      // array of betslip types
      {
        type: 3,
        name: 'Single',
        value: 'single',
        active: true,
      },
    ],
    bettingInputs: [
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true,
        label: 'multiplier',
        betId: 1,
        clientId: 1,
        betType: 'normal',
        betName: 'general_ticket',
        shortcut: '+',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 8,
          },
          {
            type: 'range',
            min: 1,
            max: 9,
          },
          {
            type: 'pattern',
            // regular expression pattern that will match partial input strings with 1 to 5 digits, with an optional decimal point followed by 1 or 2 digits
            expression: /^[1-9]\d{0,4}(\.\d{0,2})?$/, // only numbers, can be an integer or float
          },
        ],
        events: [
          {
            name: 'input',
            handler: {},
          },
          {
            name: 'keydown',
            handler: {},
          },
          {
            name: 'focus',
            handler: {},
          },
          {
            name: 'blur',
            handler: {},
          },
        ],
        /* events: [
          {
            name: 'keydown',
            handler: 'onKeydown',
          },
          {
            name: 'keydown.tab.enter.prevent',
            handler: 'onKeydownEnterTab',
          },
          {
            name: 'click',
            handler: 'onClick',
          },
        ] */
      },
    ],
    additionalBettingInputs: [
      {
        content: 'bettingInput',
        label: 'Future bet',
        clientId: 'future',
        enabled: true,
        betType: 'future',
        betName: 'Future',
        shortcut: 'f',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 10,
          },
          {
            type: 'pattern',
            expression: /^[1-9]$|^10$/,
          },
        ],
        betValidations: [],
        events: [
          {
            name: 'input',
            handler: {},
          },
          {
            name: 'keydown',
            handler: {},
          },
          {
            name: 'focus',
            handler: {},
          },
          {
            name: 'blur',
            handler: {},
          },
        ],
      },
    ],
    shortcuts: {
      '+': {
        action: 'ticket',
        active: true,
        name: 'general_new_ticket',
        shortcut: '+',
      },
      '/': {
        action: 'resetTicket',
        active: true,
        name: 'general_reset_ticket',
        shortcut: '/',
      },
      q: {
        action: 'results',
        active: true,
        name: 'general_results',
        shortcut: 'q',
      },
      f: {
        action: 'future',
        active: true,
        name: 'general_future_bet',
        shortcut: 'f',
      },
      'ctrl + M': {
        action: 'focusFirstBet',
        active: true,
        name: 'general_focus_first_bet',
        shortcut: 'ctrl + M',
      },
      'ctrl + F': {
        action: 'focusFutureRounds',
        active: true,
        name: 'edit_future_rounds',
        shortcut: 'ctrl + F',
      },
      'ctrl + S': {
        action: 'focusStakePerBet',
        active: true,
        name: 'general_edit_stake_per_bet',
        shortcut: 'ctrl + S',
      },
    },
    availableTicketActions: [
      {
        status: 'accepted',
        action: ['rebet', 'check', 'copy'],
      },
      {
        status: 'canceled',
        action: ['rebet'],
      },
      {
        status: 'paidout',
        action: ['rebet'],
      },
      {
        status: 'pending',
        action: ['checkStatus'],
      },
      {
        status: 'unknown',
        action: ['checkStatus'],
      },
      {
        status: 'failed',
        action: ['checkStatus'],
      },
      // 7Shop sets this local status, it should be paidout. This will be fixed in the future
      {
        status: 'payedout',
        action: ['rebet'],
      },
    ],
    // Ticket template layout name from CMS
    printLayoutType: {
      add: 'ticketCrashCash',
      payout: 'ticketCrashCashPayout',
      cancel: 'ticketCrashCashCancel',
      results: 'resultsCrashCash',
    },
    actionTypes: {
      add: 'TicketPayIn',
      payout: 'TicketPayout',
      cancel: 'TicketCancel',
      copy: 'TicketCopy',
    },
    oddRules: {
      minOdd: 1.01,
      maxOdd: 9999.99,
    },
  },
  GamesTest: {
    gameColor: '#819659',
    futureBet: false,
    systemBet: false,
    bettingType: 'other',
    messaging: 'WebSocket', // WebSocket support in games-bus-js  with options
    betslipTypes: [
      // array of betslip types
      {
        type: 3,
        name: 'Single',
        value: 'single',
        active: true,
      },
    ],
    bettingInputs: [
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true,
        label: 'Multiplier',
        betId: 1,
        clientId: 1,
        betType: 'normal',
        betName: 'Ticket',
        shortcut: '+',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 8,
          },
          {
            type: 'range',
            min: 1,
            max: 9,
          },
          {
            type: 'pattern',
            // regular expression pattern that will match partial input strings with 1 to 5 digits, with an optional decimal point followed by 1 or 2 digits
            expression: /^[1-9]\d{0,4}(\.\d{0,2})?$/, // only numbers, can be an integer or float
          },
        ],
        events: [
          {
            name: 'bettingInput',
            handler: {},
          },
          {
            name: 'keydown',
            handler: {},
          },
          {
            name: 'focus',
            handler: {},
          },
        ],
        /* events: [
          {
            name: 'keydown',
            handler: 'onKeydown',
          },
          {
            name: 'keydown.tab.enter.prevent',
            handler: 'onKeydownEnterTab',
          },
          {
            name: 'click',
            handler: 'onClick',
          },
        ] */
      },
    ],
    shortcuts: {
      '+': {
        action: 'ticket',
        active: true,
        name: 'newTicket',
        shortcut: '+',
      },
      '/': {
        action: 'resetTicket',
        active: true,
        name: 'resetTicket',
        shortcut: '/',
      },
      q: {
        action: 'results',
        active: true,
        name: 'results',
        shortcut: 'q',
      },
      'ctrl + M': {
        action: 'editStakePerBet',
        active: true,
        name: 'editStakePerBet',
        shortcut: 'ctrl + M',
      },
    },
    availableTicketActions: {
      rebet: {
        active: true,
      },
      check: {
        active: true,
      },
      copy: {
        active: true,
      },
    },
    // Ticket template layout name from CMS
    printLayoutType: {
      add: 'ticketCrashCash',
      payout: 'ticketCrashCashPayout',
      cancel: 'ticketCrashCashCancel',
      results: 'resultsCrashCash',
    },
  },
  VirtualSoccer: {
    gameColor: '#819659',
    futureBet: false,
    systemBet: false,
    messaging: 'Pusher',
    bettingType: 'other',
    bettingInputs: [
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true,
        label: 'Event ID',
        clientId: 1,
        betId: 1,
        betType: 'normal', // standard, bet type
        betName: 'Event ID', // displayName
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 3,
          },
          {
            type: 'range',
            min: 0,
            max: 9,
          },
          {
            type: 'pattern',
            expression: /^\d+$/, // only numbers, must be integer
          },
        ],
        events: [
          {
            name: 'input',
            handler: {},
          },
          {
            name: 'keydown',
            handler: {},
          },
          {
            name: 'click',
            handler: {},
          },
        ],
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true,
        label: 'Market ID',
        clientId: 2,
        betId: 2,
        betType: 'normal', // standard, bet type
        betName: 'Market ID', // displayName
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 7,
          },
          {
            type: 'pattern',
            expression: /^\d+$/, // only numbers, must be integer
          },
        ],
        events: [
          {
            name: 'input',
            handler: {},
          },
          {
            name: 'keydown',
            handler: {},
          },
          {
            name: 'click',
            handler: {},
          },
        ],
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true,
        label: 'Outcome ID',
        clientId: 3,
        betId: 3,
        betType: 'normal', // standard, bet type
        betName: 'Outcome ID', // displayName
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range', //  based on the market id input.
            min: 0,
            max: 10,
          },
          {
            type: 'pattern',
            expression: /^\d+$/, // only numbers, must be integer
          },
        ],
        events: [
          {
            name: 'input',
            handler: {},
          },
          {
            name: 'keydown',
            handler: {},
          },
          {
            name: 'click',
            handler: {},
          },
        ],
      },
    ],
  },
  GreyhoundRaces: {
    gameColor: '#911717',
    futureBet: false,
    systemBet: false,
    messaging: 'GcmAdapter',
    bettingType: 'races',
    bettingInputs: [
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        clientId: 0,
        betId: 0, // from config/bets ID
        betType: 'normal', // standard, bet type
        betName: 'Winner', // displayName, from config/bets name
        shortcut: '+',
        inputType: 'text',
        inputFields: 6, // depends on the number contestants, from config/numberOfContestants
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 6, // depends on the number contestants, from config/numberOfContestants
          },
          {
            type: 'pattern',
            expression: /^[1-6]$/, // only numbers, depends on the number contestants
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        clientId: 10,
        betId: 10, // from config/bets ID
        betType: 'normal', // standard, bet type
        betName: 'Forecast', // displayName, from config/bets name
        shortcut: 'f',
        inputType: 'text',
        inputFields: 6, // depends on the number contestants,  (number contestant * 2)
        inputRows: 2,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 6, // depends on the number contestants, from config/numberOfContestants
          },
          {
            type: 'pattern',
            expression: /^[1-6]$/, // only numbers, must be integer
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        clientId: 11,
        betId: 11, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Tricast', // displayName, from config/bets name
        shortcut: 'j',
        inputType: 'text',
        inputFields: 6, // depends on the number contestants,  (number contestant * 2)
        inputRows: 3,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 6, // depends on the number contestants, from config/numberOfContestants
          },
          {
            type: 'pattern',
            expression: '', // only numbers, must be integer
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        clientId: 12,
        betId: 12, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Reverse Forecast', // displayName, from config/bets name
        shortcut: 's',
        inputType: 'text',
        inputFields: 6, // depends on the number contestants,  (number contestant * 2)
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 6, // depends on the number contestants, from config/numberOfContestants
          },
          {
            type: 'pattern',
            expression: '', // only numbers, must be integer
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        clientId: 13,
        betId: 13, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Reverse Tricast', // displayName, from config/bets name
        shortcut: 'k',
        inputType: 'text',
        inputFields: 6, // depends on the number contestants,  (number contestant * 2)
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 6, // depends on the number contestants, from config/numberOfContestants
          },
          {
            type: 'pattern',
            expression: '', // only numbers, must be integer
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        clientId: 2,
        betId: 2, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Place', // displayName, from config/bets name
        shortcut: 't',
        inputType: 'text',
        inputFields: 1, // (id > 0 && id < 10)
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 6, // depends on the number contestants, from config/numberOfContestants
          },
          {
            type: 'pattern',
            expression: /^[1-6]$/, // only numbers, depends on the number contestants
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        clientId: 3,
        betId: 3, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Sum first three', // displayName, from config/bets name
        limit: 10.5,
        outcomes: ['Under', 'Over'],
        shortcut: 'w',
        inputType: 'text',
        inputFields: 1, // (id > 0 && id < 10)
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 2,
          },
          {
            type: 'pattern',
            expression: /^[1,2]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: false, // from config/bets value
        label: '',
        clientId: 4,
        betId: 4, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Sum first two', // displayName, from config/bets name
        limit: 7.5,
        outcomes: ['Under', 'Over'],
        shortcut: 'e',
        inputType: 'text',
        inputFields: 1, // (id > 0 && id < 10)
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 2,
          },
          {
            type: 'pattern',
            expression: /^[1,2]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: false, // from config/bets value
        label: '',
        clientId: 5,
        betId: 5, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Not Winner', // displayName, from config/bets name
        shortcut: 'o',
        inputType: 'text',
        inputFields: 1, // (id > 0 && id < 10)
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 6, // depends on the number contestants
          },
          {
            type: 'pattern',
            expression: /^[1-6]$/, // only numbers, depends on the number contestants
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: false, // from config/bets value
        label: '',
        clientId: 6,
        betId: 6, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Not Place', // 'Not In Two' displayName, from config/bets name
        shortcut: 'g',
        inputType: 'text',
        inputFields: 1, // (id > 0 && id < 10)
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 6, // depends on the number contestants
          },
          {
            type: 'pattern',
            expression: /^[1-6]$/, // only numbers, depends on the number contestants
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: false, // from config/bets value
        label: '',
        clientId: 7,
        betId: 7, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Not Show', // 'Not In Three' displayName, from config/bets name
        shortcut: 'h',
        inputType: 'text',
        inputFields: 1, // (id > 0 && id < 10)
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 6, // depends on the number contestants
          },
          {
            type: 'pattern',
            expression: /^[1-6]$/, // only numbers, depends on the number contestants
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: false, // from config/bets value
        label: '',
        clientId: 8,
        betId: 8, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Winner Even/Odd', // displayName, from config/bets name
        outcomes: ['Even', 'Odd'],
        shortcut: 'p',
        inputType: 'text',
        inputFields: 1, // (id > 0 && id < 10)
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 2,
          },
          {
            type: 'pattern',
            expression: /^[1-2]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: false, // from config/bets value
        label: '',
        clientId: 8,
        betId: 8, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Winner Even/Odd', // displayName, from config/bets name
        outcomes: ['Even', 'Odd'],
        shortcut: 'p',
        inputType: 'text',
        inputFields: 1, // (id > 0 && id < 10)
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 2,
          },
          {
            type: 'pattern',
            expression: /^[1-2]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: false, // from config/bets value
        label: '',
        clientId: 9,
        betId: 9, // from config/bets ID
        betType: 'special', // standard, bet type
        betName: 'Winner Under/Over', // displayName, from config/bets name
        outcomes: ['Under', 'Over'],
        shortcut: 'r',
        inputType: 'text',
        inputFields: 1, // (id > 0 && id < 10)
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 2,
          },
          {
            type: 'pattern',
            expression: /^[1-2]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
    ],
  },
  LuckySix: {
    gameColor: '#209bde',
    futureBet: true,
    systemBet: true,
    messaging: 'GcmAdapter',
    bettingType: 'draw',
    bettingInputs: [
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 0, // from config/bets ID
        clientId: 0,
        betType: 'normal', // standard, bet type
        betName: 'Ticket', // displayName, from config/bets name
        shortcut: '+',
        inputType: 'text',
        inputFields: 6,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 48,
          },
          {
            type: 'pattern',
            expression: /^[^0]$|^[1-9]$|^[1-3][0-9]$|^4[0-8]$/, // only numbers, must be integer
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 0, // from config/bets ID
        clientId: 1,
        betType: 'normal', // standard, bet type
        betName: 'Random', // displayName, from config/bets name
        shortcut: 'r',
        inputType: 'text',
        inputFields: 6,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 48,
          },
          {
            type: 'pattern',
            expression: /^[^0]$|^[1-9]$|^[1-3][0-9]$|^4[0-8]$/, // only numbers, must be integer
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 0,
        clientId: 2,
        betType: 'normal', // standard, bet type
        betName: 'Color', // displayName, from config/bets name
        outcomes: ['Red', 'Green', 'Blue', 'Purple', 'Purple', 'Yellow', 'Orange', 'Black'],
        shortcut: 'c',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 1,
          },
          {
            type: 'range',
            min: 1,
            max: 9,
          },
          {
            type: 'pattern',
            expression: /^[^0]$|^[1-9]$/, // only numbers, must be integer
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      // ???? only one object for systems
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 1, // from config/bets ID
        clientId: 3,
        betType: 'system', // standard, bet type
        betName: 'System 6/7', // displayName, from config/bets name
        shortcut: 's',
        inputType: 'text',
        inputFields: 10,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 48,
          },
          {
            type: 'pattern',
            expression: /^[^0]$|^[1-9]$|^[1-3][0-9]$|^4[0-8]$/, // only numbers, must be integer
          },
          // maybe add 'allowed lengths' 7,
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 2, // from config/bets ID
        clientId: 4,
        betType: 'system', // standard, bet type
        betName: 'System 6/8', // displayName, from config/bets name
        shortcut: 's',
        inputType: 'text',
        inputFields: 10,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 48,
          },
          {
            type: 'pattern',
            expression: /^[^0]$|^[1-9]$|^[1-3][0-9]$|^4[0-8]$/, // only numbers, must be integer
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 3, // from config/bets ID
        clientId: 5,
        betType: 'system', // standard, bet type
        betName: 'System 6/9', // displayName, from config/bets name
        shortcut: 's',
        inputType: 'text',
        inputFields: 10,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 48,
          },
          {
            type: 'pattern',
            expression: /^[^0]$|^[1-9]$|^[1-3][0-9]$|^4[0-8]$/, // only numbers, must be integer
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 4, // from config/bets ID
        clientId: 6,
        betType: 'system', // standard, bet type
        betName: 'System 6/10', // displayName, from config/bets name
        shortcut: 's',
        inputType: 'text',
        inputFields: 10,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 48,
          },
          {
            type: 'pattern',
            expression: /^[^0]$|^[1-9]$|^[1-3][0-9]$|^4[0-8]$/, // only numbers, must be integer
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 5, // from config/bets ID
        clientId: 7,
        betType: 'special', // standard, bet type
        betName: 'First Ball Colour', // displayName, from config/bets name
        outcomes: ['Red', 'Green', 'Blue', 'Purple', 'Purple', 'Yellow', 'Orange', 'Black'],
        shortcut: 's',
        inputType: 'text',
        inputFields: 4,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 1,
          },
          {
            type: 'range',
            min: 1,
            max: 8,
          },
          // maybe add 'allowed lengths' [2, 4],
          {
            type: 'pattern',
            expression: /^[1-8]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 6, // from config/bets ID
        clientId: 8,
        betType: 'special', // standard, bet type
        betName: 'Pre Numbers Sum (-122,5+)', // displayName, from config/bets name
        limit: 122.5,
        outcomes: ['Under', 'Over'],
        shortcut: 'p',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 1,
          },
          {
            type: 'range',
            min: 1,
            max: 2,
          },
          {
            type: 'pattern',
            expression: /^[1,2]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 7, // from config/bets ID
        clientId: 9,
        betType: 'special', // standard, bet type
        betName: 'First Number Even/Odd', // displayName, from config/bets name
        outcomes: ['Even', 'Odd'],
        shortcut: 'o',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 1,
          },
          {
            type: 'range',
            min: 1,
            max: 2,
          },
          {
            type: 'pattern',
            expression: /^[1,2]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 8, // from config/bets ID
        clientId: 10,
        betType: 'special', // standard, bet type
        betName: 'First Number (-24,5+)', // displayName, from config/bets name
        limit: 24.5,
        outcomes: ['Under', 'Over'],
        shortcut: 'h',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 1,
          },
          {
            type: 'range',
            min: 1,
            max: 2,
          },
          {
            type: 'pattern',
            expression: /^[1,2]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 9, // from config/bets ID
        clientId: 11,
        betType: 'special', // standard, bet type
        betName: 'More Even/Odd numbers', // displayName, from config/bets name
        outcomes: ['Even', 'Odd'],
        shortcut: 'e',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 1,
          },
          {
            type: 'range',
            min: 1,
            max: 2,
          },
          {
            type: 'pattern',
            expression: /^[1,2]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 10, // from config/bets ID
        clientId: 12,
        betType: 'special', // standard, bet type
        betName: 'Pre Balls Number', // displayName, from config/bets name
        shortcut: 'g',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 48,
          },
          {
            type: 'pattern',
            expression: /^[^0]$|^[1-9]$|^[1-3][0-9]$|^4[0-8]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        enabled: true, // from config/bets value
        label: '',
        betId: 11, // from config/bets ID
        clientId: 13,
        betType: 'special', // standard, bet type
        betName: 'Even/Odd Pre Numbers', // displayName, from config/bets name
        outcomes: ['Even', 'Odd'],
        shortcut: 'd',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 1,
          },
          {
            type: 'range',
            min: 1,
            max: 2,
          },
          {
            type: 'pattern',
            expression: /^[1,2]$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        label: 'Future bet',
        clientId: 14,
        betType: 'future', // standard, bet type
        betName: 'Future', // displayName, from config/bets name
        shortcut: 'f',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 10,
          },
          {
            type: 'pattern',
            expression: /^[1-9]$|^10$/, // only numbers, must be integer
          },
        ],
        events: {
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
      // ????
      {
        content: 'bettingInput', // if we've type of component (like input, select, text display...)
        label: '',
        betId: 15,
        clientId: 15,
        betType: 'system', // standard, bet type
        betName: 'Random system', // displayName, from config/bets name
        shortcut: 'w',
        inputType: 'text',
        inputFields: 1,
        validations: [
          {
            type: 'required',
          },
          {
            type: 'length',
            max: 2,
          },
          {
            type: 'range',
            min: 1,
            max: 10,
          },
          {
            type: 'pattern',
            expression: /^[^0]$|^[1-9]$|^[1-3][0-9]$|^4[0-8]$/, // only numbers, must be integer
          },
        ],
        events: {
          click: [
            // set focus
            {},
          ],
          keydown: [
            // on enter/tab validate input
            {},
          ],
        },
      },
    ],
  },
};
