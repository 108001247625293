import http from './http';
import store from '../store';
import { getApiRoute } from '../../config';

export default {
  async getPreviousResults() {
    const { config } = store.getters;
    const results = await http.get(
      `${getApiRoute('eventURL', config)}${getApiRoute('gameResults', config)}${config.productInstanceUuid}`,
    );
    results.data = this.formatPreviousResultsTime(results.data);
    return results.data;
  },
  formatPreviousResultsTime(results) {
    results.forEach((result) => {
      // format startedAt to a date object to print correct time
      // eslint-disable-next-line no-param-reassign
      result.startedAt = new Date(result.startedAt);
    });
    return results;
  },
};
