import http from './http';
import store from '../store';
import { getApiRoute } from '../../config';

const getQueryParams = (params) => {
  const parsedParams = [];
  const {
    config: { productId },
  } = store.getters;

  const cpvUuid = `cpvUuid=${productId}`;
  const uuid = params.requestId ? `uuid=${params.requestId}` : '';
  const barcode = params.barcode ? `id=${params.barcode}` : '';

  parsedParams.push(cpvUuid, uuid, barcode);

  return `?${parsedParams.filter((param) => param !== '').join('&')}`;
};

export default {
  payinTicket(data) {
    const { config } = store.getters;
    return http
      .post(`${getApiRoute('baseURL', config)}${getApiRoute('ticketPayin', config)}`, data)
      .then((response) => response.data);
  },
  payoutTicket(data) {
    const { config } = store.getters;
    return http
      .patch(`${getApiRoute('baseURL', config)}${getApiRoute('ticketPayout', config)}`, data)
      .then((response) => response.data);
  },
  cancelTicket(data) {
    const { config } = store.getters;
    return http
      .delete(`${getApiRoute('baseURL', config)}${getApiRoute('ticketCancel', config)}`, { data })
      .then((response) => response.data);
  },
  checkTicketBarcode(barcode) {
    const { config } = store.getters;
    return http
      .get(
        `${getApiRoute('baseURL', config)}${getApiRoute('ticketBarcodeCheck', config)}${getQueryParams({ barcode })}`,
      )
      .then((response) => response.data);
  },
  checkRequestId(requestId) {
    const { config } = store.getters;
    return http
      .get(
        `${getApiRoute('baseURL', config)}${getApiRoute('ticketRequestIdCheck', config)}${getQueryParams({
          requestId,
        })}`,
      )
      .then((response) => response.data);
  },
};
