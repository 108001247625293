export default {
  common: {},
  crashcash: {
    development: {
      baseURL: 'https://games-crash-cash-ticket.staging.de-2.nsoft.cloud',
      eventURL: 'https://games-crash-cash-event.staging.de-2.nsoft.cloud',
      upcomingEvents: '/upcoming/',
      gameResults: '/results/game/',
      ticketPayin: '/tickets',
      ticketPayout: '/tickets',
      ticketCancel: '/tickets',
      ticketBarcodeCheck: '/tickets/barcode/',
      ticketRequestIdCheck: '/tickets/',
      webSocketUrl: 'https://crash-cash-websocket.staging.de-2.nsoft.cloud',
    },
    staging: {
      baseURL: 'https://games-aggregator.staging.de-2.nsoft.cloud',
      eventURL: 'https://games-crash-cash-event.staging.de-2.nsoft.cloud', // TODO
      upcomingEvents: '/upcoming/',
      gameResults: '/results/game/',
      ticketPayin: '/seven/tickets/',
      ticketPayout: '/seven/tickets/actions/payout',
      ticketCancel: '/seven/tickets/actions/cancel',
      ticketBarcodeCheck: '/seven/tickets/barcode',
      ticketRequestIdCheck: '/seven/tickets',
      webSocketUrl: 'https://crash-cash-websocket.staging.de-2.nsoft.cloud',
    },
    production: {
      baseURL: 'https://games-aggregator.de-2.nsoft.cloud',
      eventURL: 'https://games-crash-cash-event.de-2.nsoft.cloud', // TODO
      upcomingEvents: '/upcoming/',
      gameResults: '/results/game/',
      ticketPayin: '/seven/tickets/',
      ticketPayout: '/seven/tickets/actions/payout',
      ticketCancel: '/seven/tickets/actions/cancel',
      ticketBarcodeCheck: '/seven/tickets/barcode',
      ticketRequestIdCheck: '/seven/tickets',
      webSocketUrl: 'https://crash-cash-websocket.de-2.nsoft.cloud',
    },
  },
  virtualsoccerdev: {
    development: {
      baseURL: 'https://games-vsts.dev.de-2.nsoft.cloud',
      pusherKey: 'c197ae95f575bc466e26',
    },
    staging: {
      baseURL: 'https://games-vsts.staging.de-2.nsoft.cloud',
      pusherKey: 'c197ae95f575bc466e26',
    },
    production: {
      baseURL: 'https://games-vsts.de-2.nsoft.cloud',
      pusherKey: '112cd2f19bc6b016084d',
    },
  },
};
