<template>
  <div id="app">
    <GameLoader v-if="!isAppLoaded" />
    <TemplateDefault v-else />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '@/utility';
import GameLoader from '@/components/GameLoader';
import TemplateDefault from '@/layouts/TemplateDefault';

export default {
  name: 'App',
  components: {
    GameLoader,
    TemplateDefault,
  },
  computed: {
    ...mapGetters([
      'isAppLoaded',
      'isLongerOffline',
      'isBettingDisabled',
      'connectionReconnectActive',
      'reconnectLimitReached',
    ]),
  },
  watch: {
    async isLongerOffline(newValue) {
      if (newValue === true) {
        this.setConnectionStatus(false);
        eventBus.$emit('disableInputs');
      } else {
        this.setConnectionStatus(true);
        eventBus.$emit('focusFirstBettingInput');
      }
    },
    isBettingDisabled(newValue) {
      this.setIsPayinButtonDisabled(newValue);
    },
    reconnectLimitReached(newValue) {
      if (newValue) {
        this.setIsPayinButtonDisabled(true);
        // TODO: Remove after seven fixed payin confirmation defect
        this.setBettingDisabled(true);
      }
    },
  },
  mounted() {
    eventBus.$on('reInitSocketConnection', this.reInitSocketConnectionAction);
    if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
      // eslint-disable-next-line no-console
      console.log('Store: ', this.$store);
    }
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    document.documentElement.setAttribute('theme', 'dark');
  },
  onUnmounted() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    eventBus.$off('reInitSocketConnection', this.reInitSocketConnectionAction);
  },
  methods: {
    ...mapActions(['updateOnlineStatus', 'setConnectionStatus', 'reInitBusService', 'setBettingDisabled']),
    ...mapActions('gamesBetslip', ['setIsPayinButtonDisabled']),
    reInitSocketConnectionAction() {
      if (this.connectionReconnectActive) {
        this.reInitBusService();
      }
    },
  },
};
</script>

<style lang="scss">
*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
  outline: none;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: $dark-grey;
  text-align: center;
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: Helvetica, Arial, sans-serif;
  font-size: $font-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
