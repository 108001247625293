import http from './http';
import store from '../store';
import { getApiRoute } from '../../config';

export default {
  async getUpcomingEvents() {
    const { config } = store.getters;
    const upcomingEvents = await http.get(
      `${getApiRoute('eventURL', config)}${getApiRoute('upcomingEvents', config)}${config.productInstanceUuid}`,
    );
    return upcomingEvents.data;
  },
};
