import { each } from 'lodash';
import store from '../store';

const getInputValidations = (validations) => {
  const data = {};
  each(validations, (validation) => {
    switch (validation.type) {
      case 'required':
        data.required = true;
        break;
      case 'length':
        data.maxLength = validation.max;
        data.minLength = validation.min;
        break;
      case 'range':
        data.minRangeValue = validation.min;
        data.maxRangeValue = validation.max;
        break;
      case 'pattern':
        data.expression = validation.expression;
        break;
      case 'oddsRange':
        data.minOddValue = validation.min;
        data.maxOddValue = validation.max;
        break;
      case 'allowedLengths':
        data.allowedLengths = validation.value;
        break;
      default:
    }
  });
  return data;
};

const checkValidations = (validations) => {
  const data = {};
  each(validations, (validation) => {
    switch (validation.type) {
      case 'required':
        data.required = true;
        break;
      case 'length':
        data.maxLength = validation.max;
        break;
      case 'range':
        data.minRangeValue = validation.min;
        data.maxRangeValue = validation.max;
        break;
      case 'pattern':
        data.expression = validation.expression;
        break;
      default:
    }
  });
  return data;
};

const validateInput = (value, pattern) => pattern.test(value);
const validateOdd = function (odd) {
  const rules = store.state.gameConfig.oddRules;
  if (odd < rules.minOdd) {
    return { isValid: false, rule: 'general_rule_minOdds', value: rules.minOdd };
  } else if (odd >= rules.maxOdd) {
    return { isValid: false, rule: 'general_rule_maxOdds', value: rules.maxOdd };
  }
  return {
    isValid: true,
    rule: false,
    value: NaN,
  };
};

export default {
  checkValidations,
  validateInput,
  validateOdd,
  getInputValidations,
};
