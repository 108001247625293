import http from './http';
import { getApiRoute } from '../../config';
import store from '../store';

export default {
  async getCodes(channels) {
    const { config } = store.getters;
    const response = await http.get(`${getApiRoute('webSocketUrl', config)}/grant-codes`, {
      headers: {
        'X-NSFT-NGS-CHANNELS': channels,
        'X-NSFT-NGS-SUBSCRIPTIONID': `${config.productId}`,
        'X-NSFT-NGS-DEVICEID': `${config.deviceId}`,
      },
    });

    return response.data;
  },
};
