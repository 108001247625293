import { eventBus, betslipUtility } from '../utility';
import store from '../store';

const successTicketHandler = function (data) {
  // console.log(' successTicketHandler ', data);
  eventBus.$emit('resetInput');
  store.dispatch('betslip/disablePayinButton', false);
  store.dispatch('betslip/clearBetslip', true);

  // Send message over GGateway event 'Tickets.Update' to update state of ticket
  const ticketData = {
    action: store.getters.ticketAction, // available values: Add, Cancel, Payout
    ticket: betslipUtility.prepareSevenTicketData(data.productTicket || data),
  };
  // console.log(' ticketData ', ticketData);
  eventBus.$emit('ticketUpdate', ticketData);
  // 8693v40hz
  if (store.state.ticketAction === 'Cancel' && data.status.value !== 'CANCELED') return;
  if (store.state.ticketAction === 'Payout' && data.status.value !== 'PAIDOUT') return;
  if (!store.getters.ticketActionSuccessful || data.status.value === 'INVALID') return;
  eventBus.$emit('printTemplate', ticketData);
};

const errorTicketHandler = function (error, ticket = {}) {
  // console.log(' errorTicketHandler ', error);
  // console.log(' errorTicketHandler ticket ', ticket);
  const errorMessage = store.getters.getTranslation(
    error.response?.data?.message || error.response?.data?.error || error.message,
  );
  store.dispatch('betslip/disablePayinButton', false);

  const ticketData = {
    action: ticket.action || error.action || store.state.ticketAction, // available values: Add, Cancel, Payout
    ticket: {
      // only set requestUuid and status rejected
      requestUuid: ticket.requestUuid || error.requestId,
      status: {
        value: 'REJECTED',
      },
      payin: store.getters.config.rules?.minBetAmount.value,
    },
  };
  // Handling axios request creation errors
  // If error isn't from backend don't send ticket update
  // Covers case where ticketUpdate and ticketCheck responses are missing on ticket payin action
  if ((error.response && error.response.status === 404) || error.error) {
    eventBus.$emit('ticketUpdate', ticketData);
    return;
  }
  store.dispatch('sendGGMessage', { message: errorMessage });
};

const successTicketCheckHandler = function (data) {
  eventBus.$emit('resetInput');
  store.dispatch('betslip/disablePayinButton', false);
  store.dispatch('betslip/clearBetslip', true);

  // Send message over GGateway event 'Tickets.Update' to update state of ticket
  const ticketData = {
    action: store.getters.ticketAction, // available values: Add, Cancel, Payout
    ticket: betslipUtility.prepareSevenTicketData(data.productTicket || data),
  };
  eventBus.$emit('ticketUpdate', ticketData);
  // 8693v40hz
  if (store.state.ticketAction === 'Cancel' && data.status.value !== 'CANCELED') return;
  if (store.state.ticketAction === 'Payout' && data.status.value !== 'PAIDOUT') return;
  if (!store.getters.ticketActionSuccessful || data.status.value === 'INVALID') return;

  const ticket = {
    id: data.id,
    requestUuid: data.requestUuid,
  };
  const { ticketsPrintedByTicketUpdate } = store.getters;
  // Check if in the meantime the ticket update caused ticket print
  if (ticketsPrintedByTicketUpdate.some((printedTicket) => printedTicket.requestUuid === ticket.requestUuid)) return;
  store.commit('SET_PRINTED_TICKET_BY_TICKET_CHECK', ticket);
  eventBus.$emit('printTemplate', ticketData);
};

export { successTicketHandler, successTicketCheckHandler, errorTicketHandler };
